import { FC } from 'react';
import { PageProps } from 'gatsby';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from './sections/Intro';
import Content from './sections/Content';
import RecommendedPosts from './sections/RecommendedPosts';
import { PostBlog } from 'src/cms/data/blog/usePostsData';

import * as styles from './Post.module.scss';

const Post: FC<PageProps<{}, { post: PostBlog }>> = ({
  pageContext: { post },
}) => {
  if (!post) {
    return null;
  }

  return (
    <div className={styles.root}>
      <PageLayout
        withStartProjectForm={false}
        withStartProjectButton
        description={post.metaDescription}
        withFooter
      >
        <div className={styles.content}>
          <Intro
            title={post.title}
            subtitle={`By ${post.author} | ${post.date} | ${post.theme}`}
            image={post?.bigImage?.childImageSharp?.gatsbyImageData}
          />

          <Content body={post.body} />

          <RecommendedPosts id={post.id} />
        </div>
      </PageLayout>
    </div>
  );
};

export default Post;
