// components
import Seo from 'src/components/Seo';
// types
import type { PageProps } from 'gatsby';
import type { FC } from 'react';
import type { PostBlog } from 'src/cms/data/blog/usePostsData';

export const Head: FC<PageProps<{}, { post: PostBlog }>> = ({
  location,
  pageContext: { post },
}) => {
  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={post?.metaTitle}
      description={post?.metaDescription}
      schema={{
        schemaContext: post?.schema?.schemaContext ?? 'https://schema.org',
        schemaType: post?.schema?.schemaType ?? 'Article',
        schemaName: post?.schema?.schemaName ?? post?.metaTitle,
        schemaDescription:
          post?.schema?.schemaDescription ?? post?.metaDescription,
        schemaUrl:
          post?.schema?.schemaUrl ?? `https://seedium.io${location.pathname}`,
        schemaLogo:
          post?.schema?.schemaLogo ?? 'https://seedium.io/images/logo.png',
      }}
    />
  );
};

export { default } from './Post';
